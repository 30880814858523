import React from 'react';
import {AppBar, Toolbar, Button, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom"
import FizStatus from "./components/FizStatus";


const Navbar = () => {
    const navigate = useNavigate();

    const handleNavigation = (route: string) => {
        navigate(route);
    };

    return (
        <AppBar position="sticky" style={{height:64}}>
            <Toolbar style={{justifyContent: "center"}}>
                <Button color="inherit" style={{margin: 10}} onClick={() => handleNavigation('/')}>
                    Day
                </Button>
                <Button color="inherit" style={{margin: 10}} onClick={() => handleNavigation('/week')}>
                    Week
                </Button>
                <Button color="inherit" style={{margin: 10}} onClick={() => handleNavigation('/trend')}>
                    Trend
                </Button>
                <Button color="inherit" style={{margin: 10}} onClick={() => handleNavigation('/calendar')}>
                    Calendar
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
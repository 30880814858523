import React, {useEffect, useState} from 'react';
import useWindowDimensions from "../hooks/useWindowDimensions";
import FizStatus from "../components/FizStatus";
import {useParams} from "react-router-dom";
import {da} from "date-fns/locale";

interface StatisticPageProps {
    url: string
}


async function getLastModifiedDate(imagePath: string) {
    try {
        const response = await fetch(imagePath + ".svg", {
            method: 'HEAD',
        });

        if (response.ok) {
            const lastModified: string | null = response.headers.get('Last-Modified');
            if (lastModified) {
                return new Date(lastModified).toLocaleString(undefined, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false
                });
            }

        }
        return ""
    } catch (error) {
        return ""
    }
}

const StatisticPage = ({url}: StatisticPageProps) => {
    const {date} = useParams()
    const isOldDay = date && new Date(date).toDateString() !== new Date().toDateString()
    if (isOldDay) url = "/details/day/" + date
    const [modifiedDate, setModifiedDate] = useState("");
    const [imageUrl, setImageUrl] = useState("?0");
    const {height, width} = useWindowDimensions();
    const isMobile = height / width > 1.2

    useEffect(() => {
        const fetchLastModifiedDate = () => {
            getLastModifiedDate(url).then((date) => setModifiedDate(date));
        };

        fetchLastModifiedDate();
        const intervalId = setInterval(fetchLastModifiedDate, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [url]);

    useEffect(() => {
        const updateImageUrl = () => {
            setImageUrl(`?${new Date().getTime()}`);
        };

        const intervalId = setInterval(updateImageUrl, 30000);

        return () => {
            clearInterval(intervalId);
        };
    }, [url]);
    return (
        <div style={{maxWidth: isMobile ? "90%" : "70%"}}>
            {isOldDay ? <p>Data from: {date}</p> : <FizStatus/>}

            <img
                style={{maxWidth: "100%"}}
                src={`${url}${isMobile ? "-mobile" : ""}.svg${imageUrl}`}
                alt={url}
                onDragStart={(event) => event.preventDefault()}
            />
            <p
                style={{
                    width: "100%",
                    textAlign: "right",
                    margin: 0,
                    fontSize: 20,
                    color: "gainsboro",
                }}
            >
                Last Updated: {modifiedDate}
            </p>
        </div>
    );
};

export default StatisticPage;
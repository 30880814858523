import React, {useEffect, useState} from "react";

type OpeningHours = {
    start: number;
    end: number;
};

const openingTimes: Record<number, OpeningHours> = {
    0: {start: 8.5, end: 19}, // Sunday
    1: {start: 7, end: 23.5}, // Monday
    2: {start: 7, end: 23.5}, // Tuesday
    3: {start: 7, end: 23.5}, // Wednesday
    4: {start: 7, end: 23.5}, // Thursday
    5: {start: 7, end: 23.5}, // Friday
    6: {start: 8.5, end: 19}, // Saturday
};

async function getCurrentFizUser(open?: boolean) {
    if (!open) {
        return 0
    }
    try {
        const response = await fetch("https://intern.sport.uni-goettingen.de/fizdb/getjson.php?action=fiz");
        console.log("request")
        if (response.ok) {
            return JSON.parse(await response.text())[0]["Anzahl"]
        }
        return 0
    } catch (error) {
        return 0
    }
}

const FizStatus = () => {
    const [now, setNow] = useState(new Date());
    const [currentUser, setCurrentUser] = useState(0);

    const germanTime = new Date(now.toLocaleString("en-US", {timeZone: "Europe/Berlin"}));
    const day = germanTime.getDay();
    const times = openingTimes[day];
    const timesNext = openingTimes[(day + 1) % 7];
    let open = false;
    const time = germanTime.getHours() + germanTime.getMinutes() / 60;
    if (times.start < time && times.end > time) {
        open = true;
    }
    const remainingTime = Math.round((times.end - time) * 60);
    const timeUntilOpen = Math.round((((timesNext.start - time) + 24) % 24) * 60);

    useEffect(() => {
        const fetchCurrentFizUser = () => {
            getCurrentFizUser(open).then((user) => setCurrentUser(user));
        };

        fetchCurrentFizUser();
        const intervalId = setInterval(fetchCurrentFizUser, 120000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const formatTime = (minutes: number) => {
        if (minutes <= 120) {
            return `${minutes} min`;
        } else {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours}h:${remainingMinutes.toString().padStart(2, "0")}m`;
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <p>
            The FIZ is
            currently {open ? `open and there are ${currentUser} people` : "closed"}. {open ? `It closes in ${formatTime(remainingTime)}.` : `It opens again in ${formatTime(timeUntilOpen)}.`}
        </p>
    );
};

export default FizStatus;

import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Navbar from './Navbar';
import StatisticPage from './pages/StatisticPage';
import './App.css';
import {createTheme, ThemeProvider} from "@mui/material";
import HeatmapPage from "./pages/HeatmapPage";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});
const App = () => {
    return (
        <Router>
            <ThemeProvider theme={darkTheme}>
                <div className="App">
                    <Navbar/>

                    <header className="App-header">
                        <Routes>
                            <Route path="/calendar"
                                   element={<HeatmapPage csvUrl={"/data"}/>}/>
                            <Route path="/details/:date" element={<StatisticPage url={"/day"}/>}/>
                            <Route path="/trend" element={<StatisticPage url={"/trend"}/>}/>
                            <Route path="/week" element={<StatisticPage url={"/weekdays"}/>}/>
                            <Route path="/" element={<StatisticPage url={"/day"}/>}/>
                        </Routes>
                    </header>
                </div>
            </ThemeProvider>
        </Router>
    );
};

export default App;
import React, {useEffect, useState} from "react";
import CalendarHeatmap from "react-calendar-heatmap";
import "react-calendar-heatmap/dist/styles.css";
import Papa from "papaparse";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../hooks/useWindowDimensions";

type CsvData = {
    date: string;
    rest: number;
    website: number;
};

type HeatmapPageProps = {
    csvUrl: string;
};

const HeatmapPage: React.FC<HeatmapPageProps> = ({csvUrl}) => {
    const [data, setData] = useState<CsvData[]>([]);
    const {height, width} = useWindowDimensions();
    const isMobile = height / width > 1.2

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(csvUrl);
            const csvData = await response.text();
            Papa.parse(csvData, {
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
                complete: (results) => {
                    setData(results.data as CsvData[]);
                },
            });
        };

        fetchData();
    }, [csvUrl]);
    // Helper function to group data by day and calculate the mean of the "website" column
    const groupByDay = (data: CsvData[]) => {
        const groupedData: { [date: string]: { count: number; sum: number } } = {};

        data.forEach((row) => {
            const date = new Date(row.date.split(" ")[0].replaceAll("-", "/"));
            const day = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`

            if (!groupedData[day]) {
                groupedData[day] = {count: 0, sum: 0};
            }
            if (row.website > 0) {
                groupedData[day].count += 1;
                groupedData[day].sum += row.website;
            }
        });

        return Object.entries(groupedData).map(([date, {count, sum}]) => ({
            date,
            mean: sum / count,
        })).filter((value) => value.mean);
    };

    const groupedData = groupByDay(data);

    const navigate = useNavigate();
    return (
        <div style={{width: isMobile ? "80%" : "70%"}}>
            <CalendarHeatmap
                showWeekdayLabels={true}
                startDate={new Date(new Date().getFullYear(), new Date().getMonth() - (isMobile ? 2 : 6), new Date().getDate(), 12)}
                endDate={new Date()}
                onClick={(value) => {
                    if (!value) return
                    navigate("/details/" + value.dateString)
                }}
                values={groupedData.map((row) => {
                        const cellDate = new Date(row.date.replaceAll("-", "/"))
                        return {
                            date: new Date(cellDate.getFullYear(), cellDate.getMonth(), cellDate.getDate(), 12),
                            dateString: row.date,
                            count: row.mean,
                        }
                    }
                )}
                classForValue={(value) => {
                    if (!value) {
                        return 'color-empty';
                    }
                    return `color-github-${Math.max(Math.min(Math.floor((value.count-50) / 7), 3) + 1,1)}`;
                }}
                titleForValue={(value) => {
                    if (value) return `${value.dateString}: ${Math.floor(value.count)}`
                }}
            />
        </div>
    );
};

export default HeatmapPage;
